import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'home';
export default {

    loadInfosHomes(context) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`)
                .then(response => {
                    context.commit('LOAD_INFO_HOME', response.data)
                    return resolve(response.data)
                })
                .catch((e) => reject(e))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

}