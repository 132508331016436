import AdminComponent from "@/components/dashboard/AdminComponent";

import CampaignSearch from "@/views/search/CampaignSearch";
import CampaignCreate from "@/views/create/CampaignCreate";

export default {
    path: '/campaign',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'campaign-search', component: CampaignSearch},
        {path: 'create/:id', name: 'campaign-create', component: CampaignCreate, props: true}
    ]
}