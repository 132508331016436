<template>
    <div class="modal-window" v-if="modal_file || modal_preview">
        <CampaignAddFiles :id="this.id" @close="close" v-if="modal_file"/>

        <div v-if="modal_preview" @click="closePreview" role="button" class="btn-close-preview">X</div>
        <img v-if="preview.type === '1' && modal_preview" :src="preview.show_url" alt="">
        <video v-if="preview.type === '2' && modal_preview" :src="preview.show_url" autoplay loop controls></video>
    </div>

    <painel-component>
        <div class="clearfix">
            <div class="btn-group float-end" role="group">
                <buttons-default @click="modal_file = true" :items="itemsAdd"/>
                <buttons-default @click="syncFiles" :items="itemsSync"/>
                <buttons-default @click="deleteItems" :disabled="destroy.files_ids.length < 1" :items="itemsDel"/>
            </div>
        </div>
        <row-component class="mt-5">
            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                               v-for="(data, index) in formData" :key="index">
                <input :value="data.id" v-model="destroy.files_ids" type="checkbox" :id="'files' + index">
                <label class="file-content" :for="'files' + index">
                    <span class="d-flex align-items-center">
                        <img class="mx-2" v-if="data.type === '1'" src="@/assets/icon-avisos/2.png" alt="">
                        <img class="mx-2" v-else src="@/assets/icon-avisos/3.png" alt="">
                        <span class="d-inline-block text-truncate" style="max-width: 150px;">
                            {{ data.name }}
                        </span>
                    </span>
                    <span class="action-buttons" v-if="destroy.files_ids.length < 1">
                        <button class="disabled">
                            <i :class="['sync icon', data.synced === '2' ? 'sync-bg' : '']"></i>
                        </button>
                        <button @click="previewShow(data)">
                            <i class="icon eye"></i>
                        </button>
                    </span>
                </label>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowColComponent from "@/components/row/RowColComponent";
import RowComponent from "@/components/row/RowComponent";
import ButtonsDefault from "@/components/buttons/ButtonsDefault";
import CampaignAddFiles from "@/views/create/CampaignAddFile";
import {useToast} from "vue-toastification";

const toast = useToast()

export default {
    name: "CampaignCreate",
    components: {
        RowComponent,
        RowColComponent,
        CampaignAddFiles,
        PainelComponent,
        ButtonsDefault,
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Conteúdos')
        this.loadItems()
    },

    data() {
        return {
            itemsAdd: {
                icon: 'icon plus',
                type: 'button',
            },

            itemsSync: {
                icon: 'sync alternate icon',
                type: 'button',
            },

            itemsDel: {
                icon: 'icon trash',
                type: 'button',
            },

            formData: [],

            destroy: {
                id: this.id,
                files_ids: []
            },

            preview: '',
            modal_file: false,
            modal_preview: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadCampaignsFiles', this.id)
                .then((r) => {
                    this.formData = r
                }).catch(() => {
                toast.error("Erro desconhecido!", {timeout: 1500})
            })
        },

        syncFiles() {
            let params = {id: this.id}
            this.$store.dispatch('storeCampaignsFilesSynchronize', params)
                .then(() => {
                    this.loadItems()
                    toast.success("Sincronizado com sucesso", {timeout: 1500})
                }).catch(() => {
                toast.error("Erro ao sincronizar", {timeout: 1500})
                this.loadItems()
            });
        },

        deleteItems() {
            this.$store.dispatch('destroyCampaignFile', this.destroy)
                .then(() => {
                    toast.info("Deletado com sucesso", {timeout: 1500})
                    this.loadItems()
                    this.destroy.files_ids = []
                }).catch(() => {
                toast.error("Erro ao deletar", {timeout: 1500})
                this.loadItems()
                this.destroy.files_ids = []
            });
        },

        close(n) {
            this.modal_file = false
            if (n === 1)
                this.loadItems()
        },

        closePreview() {
            this.modal_preview = false
        },

        previewShow(items) {
            this.preview = items
            this.modal_preview = true
        }
    },
}
</script>

<style scoped>
.modal-window {
    top: 0;
    left: 0;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    background: rgb(0, 0, 0, 0.6);
}

.btn-close-preview {
    top: 0;
    right: 5%;
    margin: 2%;
    color: white;
    cursor: pointer;
    font-size: 2rem;
    position: absolute;
}

.modal-window > img,
.modal-window > video {
    display: block;
    max-width: 80vw;
    max-height: 80vh;
    position: relative;
    box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    transition: -webkit-transform 0.3s ease-in-out;
    -webkit-box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.file-content {
    width: 100%;
    height: 60px;
    display: flex;
    color: black;
    padding: 15px 0;
    position: relative;
    align-items: center;
    background: #ebebeb;
    justify-content: space-between;
}

.file-content span img {
    width: 50px;
    height: auto;
}

input[type="checkbox"] {
    display: none;
}

.file-content:before {
    content: "";
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    display: grid;
    color: #eeeeee;
    position: absolute;
    border-radius: 50%;
    place-items: center;
    transform: scale(0);
    transition-duration: 0.4s;
}

input:checked + label:before {
    content: "✓";
    transform: scale(1);
    background-color: grey;
}

.file-content .action-buttons {
    right: 0;
    width: 30%;
    height: 100%;
    display: flex;
    position: absolute;
}

.file-content .action-buttons button {
    border: 0;
    width: 100%;
    height: 100%;
    outline: none;
    background: #ebebeb;
}

.file-content .action-buttons button:not(.disabled):hover {
    background-color: #cacaca;
}

.disabled {
    opacity: 0.5;
}

.sync-bg {
    filter: invert(47%) sepia(97%) saturate(470%) hue-rotate(75deg) brightness(102%) contrast(84%)
}
</style>