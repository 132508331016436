<template>
  <div :class="classRowCol" class="mb-4">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "RowColComponent",

  props: {
    classRowCol: {
      'type': String,
      "required": true,
    }
  }
}
</script>