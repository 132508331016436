<template>
    <painel-component :title="'Adicionar Items'">
        <error-component :errors="errors" v-if="showError"/>

        <div class="btn-close" @click="$emit('close')"></div>
        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.name}">
                    <label>Nome</label>
                    <input-component v-model="formDate.name" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.notice}">
                    <QuillEditor :contentType="'html'" ref="myEditor" v-model:content="formDate.notice" theme="snow"
                                 style="height: 250px; width: 100%;" :toolbar="toolbar"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <buttons-default :items="buttonDefault" @submit="submit"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from "@/components/forms/InputComponent";
import ButtonsDefault from "@/components/buttons/ButtonsDefault";

import {Quill, QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

var Size = Quill.import("attributors/style/size");

Size.whitelist = [
    "8px",
    "9px",
    "10px",
    "11px",
    "12px",
    "14px",
    "18px",
    "24px",
    "36px",
    "48px",
    "64px",
    "82px",
    "150px",
];
Quill.register(Size, true);

import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent.vue";

const toast = useToast()

export default {
    name: 'NoticeAddItems',
    components: {
        ErrorComponent,
        PainelComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        ButtonsDefault,
        QuillEditor
    },

    props: {
        id: {required: true},
    },

    data() {
        return {
            buttonDefault: {
                type: 'button',
                icon: 'plus',
                name: 'Adicionar',
                title: 'Adicionar'
            },

            formDate: {
                app_id: this.id,
                name: '',
                notice: ''
            },

            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['link', 'image'],
                [{'header': 1}, {'header': 2}],               // custom button values
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                [{'direction': 'rtl'}],                         // text direction

                [{
                    size: [
                        "8px",
                        "9px",
                        "10px",
                        "11px",
                        "12px",
                        "14px",
                        "18px",
                        "24px",
                        "36px",
                        "48px",
                        "64px",
                        "82px",
                        "150px",
                    ],
                },
                ], // custom dropdown
                [{'header': [1, 2, 3, 4, 5, 6, false]}],

                [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                [{'align': []}],                                        // remove formatting button
            ],

            errors: [],
            showError: false,

        }
    },

    methods: {

        submit() {
            this.$store.dispatch('storeNoticeAppAddItems', this.formDate)
                .then(() => {
                    toast.success('Item adicionar com sucesso', {timeout: 1500})
                    this.$emit('close')
                    this.showError = false
                    this.errors = []
                })
                .catch((r) => {
                    toast.error('Erro ao adicionar item', {timeout: 1500})
                    this.showError = true
                    this.errors = r.response.data.errors;
                })
        },
    },
}
</script>

<style scoped>
.btn-close {
    top: 0;
    right: 0;
    margin: 2%;
    position: absolute;
}
</style>

<style>
.ql-snow .ql-picker.ql-size .ql-picker-label::before, .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value) !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    overflow: auto;
    height: 250px;
    border-color: #d1d5db;
}
</style>