<template>
    <div class="modal-create-folder">
        <painel-component :title="'Arquivos Selecionados'">
            <div class="modal-folder-close" @click="$emit('close')">X</div>
            <div class="container">
                <form>
                    <div>
                        <label @click="cleanUploadFile" class="uploadButton" for="upload">
                            <input type="file" ref="uploadfiles" @change="selectFiles"
                                   id="upload" multiple
                                   data-max-file-size="30MB" accept="image/*,video/*">
                            <i class="icon plus"></i>
                        </label>
                    </div>
                    <div class="files">
                        <ul>
                            <li v-for="(data, index) in files" :key="index"
                                class="d-flex align-items-center justify-content-between">
                                <div style="width: 60%" class="d-flex justify-content-between">
                                    <div class="text-truncate w-50">
                                        {{ data.file.name }}
                                    </div>
                                    <div class="file-size w-50">
                                        {{ convertBytes(data.file.size) }}
                                    </div>
                                </div>

                                <button class="remove-file" type="button" @click="remove(index)">
                                    <i class="trash alternate outline icon"></i>
                                </button>

                                <div class="me-5 align-items-center justify-content-around"
                                     style="width: 20%; display: flex" :id="'progress-bar' + index">
                                    <progress :value="data.progress" max="100"></progress>
                                    {{ data.progress }}%
                                </div>

                                <div class="file-success" :id="'file-success' + index">
                                    Sucesso ao enviar
                                    <i class="check icon"></i>
                                </div>

                                <div class="file-erro" :id="'file-erro' + index">
                                    Erro ao enviar
                                    <i class="close icon"></i>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <buttons-default :disabled="files >= 0 || sendForm" :items="buttonDefaultTwo"
                                     @submit="uploadFiles"/>
                </form>
            </div>
        </painel-component>
    </div>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ButtonsDefault from "@/components/buttons/ButtonsDefault.vue";

import {useToast} from "vue-toastification";
import axios from "axios";

const toast = useToast()

export default {
    name: "NoticeAddFiles",
    components: {
        PainelComponent,
        ButtonsDefault
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            buttonDefaultTwo: {
                icon: 'upload icon',
                type: 'button',
                name: 'Enviar',
                title: 'Enviar'
            },

            contUpload: 0,

            files: [],
            sendForm: false
        }
    },

    methods: {
        selectFiles(event) {
            this.files = Array.from(event.target.files).map((file) => ({
                file,
                app_id: this.id,
            }));
        },

        remove(index) {
            this.files = Array.from(this.files)
            this.files.splice(index, 1)
        },

        convertBytes(size) {
            if (size >= 1024 && size < 1024000) {
                size = Math.round((size / 1024) * 100) / 100;
                return size + ' KB'
            } else if (size >= 1024000) {
                size = Math.round((size / 1024000) * 100) / 100;
                return size + " MB"
            }
        },

        cleanUploadFile() {
            this.files = []
        },

        uploadFiles() {
            this.sendForm = true
            let removeFileClass = document.querySelectorAll(".remove-file");
            removeFileClass.forEach((element) => {
                element.style.display = 'none'
            });
            this.files.forEach((fileObject, index) => {
                const params = {
                    file: fileObject.file,
                    app_id: this.id
                }
                axios.post("api/v1/trustee/notice-add-file", params, {
                    headers: {'Content-Type': 'multipart/form-data'},
                    onUploadProgress: (progressEvent) => {
                        fileObject.progress = Math.round(
                            (progressEvent.loaded / progressEvent.total) * 100
                        );
                    },
                }).then(() => {
                    document.getElementById('file-success' + index).style.display = 'block'
                    document.getElementById('progress-bar' + index).style.display = 'none'
                    fileObject.progress = 0;
                    this.contUpload += 1
                    if (this.contUpload === this.files.length) {
                        this.sendForm = false
                        this.files = []
                        this.$emit('close', 1)
                    }
                }).catch((r) => {
                    document.getElementById('file-erro' + index).style.display = 'block'
                    document.getElementById('progress-bar' + index).style.display = 'none'
                    fileObject.progress = 0;
                    this.contUpload += 1
                    if (this.contUpload === this.files.length) {
                        this.sendForm = false
                        this.files = []
                        this.$emit('close', 1)
                        let err = r.response.data.errors[0].toString()
                        toast.error(err, {timeout: 1500})
                    }
                });
            });
        },
    },
}
</script>

<style scoped>
.modal-create-folder {
    position: relative;
    height: 55%;
    width: 40%;
}

.modal-folder-close {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.5rem;
    margin: 10px 20px;
    cursor: pointer;
}

input[type=file] {
    position: absolute;
    right: -9999px;
    visibility: hidden;
    opacity: 0;
}

input[type=button] {
    position: relative;
    padding: 1rem 3rem;
    background: #FF7700;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    border-radius: 5px;
    border: 0;
    color: #fff;
}

.uploadButton {
    display: grid;
    line-height: 16px;
    height: 42px;
    width: 42px;
    background: #FF7700;
    border-radius: 5px;
    border: 0;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.5s ease-out;
    place-items: center;
}

.uploadButton i.icon {
    margin: 0;
}

div.files {
    padding: 1rem 0;
    margin: 1rem 0;
}

div.files ul {
    list-style: none;
    padding: 0;
    max-height: 160px;
    overflow: auto;
}

div.files ul li {
    width: 99%;
    background: #eee;
    margin: 0.5rem 0;
    padding: 0.5rem;
    position: relative;
}

div.files ul li button {
    border: 0;
    outline: none;
    position: absolute;
    right: 0;
    margin-right: 0.5rem;
}

div.container {
    max-width: 100%;
    padding: 0;
}

span.file-size {
    color: #999;
    padding-left: 0.5rem;
}

.file-success > i {
    filter: invert(50%) sepia(99%) saturate(426%) hue-rotate(74deg) brightness(96%) contrast(87%);
}

.file-erro > i {
    filter: invert(23%) sepia(80%) saturate(2715%) hue-rotate(352deg) brightness(95%) contrast(81%)
}

.file-success,
.file-erro {
    display: none;
    width: fit-content;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
}

progress {
    border-radius: 8px;
    border: 1px solid #fbfbfb;
}

progress::-webkit-progress-bar {
    background-color: #fbfbfb;
}

progress::-webkit-progress-value {
    background-color: #FF7700;
}
</style>