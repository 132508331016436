<template>
    <pre-loader/>
    <router-view/>
</template>

<script>
import PreLoader from "@/views/PreLoader";

export default {
    name: 'App',
    components: {
        PreLoader
    }
}
</script>