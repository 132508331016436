<template>
    <painel-component :title="'Usuário'">

        <div class="aux mb-4">
            <div class="avatar-form">
                <img v-if="me.photo" :src="me.photo" class="avatar__image" alt="">
                <img v-else src="@/assets/avatar.png" class="avatar__image" alt="">
            </div>
            <p>{{ me.fullName }}</p>
            <router-link :to="{ name: 'user-update-photo' }" class="figure">
                <i class="camera icon"></i>
            </router-link>
        </div>

        <form autocomplete="off">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Senha:</label>
                    <input-component :type-input="'password'" v-model="formDate.password" autocomplete="new-password"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Confirmação de Senha:</label>
                    <input-component :type-input="'password'" v-model="formDate.passwordconfirm"
                                     autocomplete="new-password"/>
                </row-col-component>
            </row-component>

            <buttons-default :items="buttonDefault" @submit="submit"/>

        </form>

    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import ButtonsDefault from "@/components/buttons/ButtonsDefault";

import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "UserUpdatePassword",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonsDefault
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Usuário')
    },

    data() {
        return {
            buttonDefault: {
                name: "Alterar Senha",
                title: "Alterar Senha",
                icon: "save",
                type: "button"
            },

            formDate: {
                password: '',
                passwordconfirm: '',
            },

            sendForm: false,
            updatePassword: false
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateUserPassword', this.formDate)
                    .then(() => {
                        this.sendForm = false
                        toast.success('Senha atualizada com sucesso', {timeout: 1500});
                        this.$router.push({name: 'condominium'})
                    }).catch((r) => {
                    this.sendForm = false
                    console.log(r)
                    toast.error("Erro ao atualizar senha", {timeout: 1500});
                })
            }
        },
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },
    },

}
</script>

