import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'campaign';
export default {

    loadCampaigns(context) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`)
                .then(response => {
                    context.commit('LOAD_CAMPAIGN', response.data)
                    return resolve(response.data)
                })
                .catch((e) => reject(e))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadCampaignsFiles(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}campaign-get-file/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit('PRELOADER', false))
        })
    },

    storeCampaignsFilesSynchronize(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}campaign-synchronize`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyCampaignFile(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}campaign-file-destroy/${formData.id}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },
}