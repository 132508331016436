import actions from '@/store/modules/notice/actions'
import getters from '@/store/modules/notice/getters'
import mutations from '@/store/modules/notice/mutations'
import state from '@/store/modules/notice/state'

export default {
    actions,
    getters,
    mutations,
    state
}