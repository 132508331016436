import AdminComponent from "@/components/dashboard/AdminComponent";
import NoticeSearch from "@/views/search/NoticeSearch";

export default {
    path: '/notice',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search/:id', name: 'notice-search', component: NoticeSearch, props: true}
    ]
}