<template>
    <div class="menuContainer" :class="{ openMenuContainer: openMenu }">

        <div class="logoContainer">
            <router-link to="/">
                <img src="@/assets/logo.png" alt="D7M marketing">
            </router-link>
        </div>

        <div class="userContainer">
            <div class="avatar">
                <img v-if="me.photo" :src="me.photo" alt="">
                <img v-else src="@/assets/avatar.png" alt="">
                <p>{{ me.name }}</p>
            </div>
        </div>

        <div class="linkContainer">
            <ul>
                <li v-if="campaign">
                    <router-link v-if="campaign" :to="route_camp.route" class="link-route">
                        <i :class="route_camp.icons"></i> {{ route_camp.name }}
                    </router-link>
                </li>

                <li v-for="(link, index) in linkMenu.links" :key="index">
                    <router-link v-if="link.route" :to="link.route" class="link-route">
                        <i :class="link.icons"></i> {{ link.name }}
                    </router-link>

                    <router-link v-else to="/" class="link-route">
                        <i :class="link.icons"></i> {{ link.name }}
                    </router-link>
                </li>

                <li>
                    <button @click="logout" class="link-route">
                        <i class="icon sign-out"></i> Sair
                    </button>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
import linkMenu from "@/configs/linkMenu";
import {useToast} from "vue-toastification";

const toast = useToast()

export default {
    name: "NavMenu",

    props: {
        openMenu: {
            "required": true,
            "type": Boolean,
        },
    },

    created() {
        this.permissionsCampaign()
    },

    data() {
        return {
            linkMenu,
            campaign: '',
            route_camp: {
                name: 'Campanha',
                icons: 'tv icon',
                route: '/campaign/search',
            },
        }
    },

    methods: {
        logout() {
            this.$store.dispatch('logout').then(() => {
                toast.success('Logout efetuado com sucesso', {timeout: 1500})
                setTimeout(() => this.$router.push({name: 'login'}), 1200)
            })
        },


        permissionsCampaign() {
            this.$store.dispatch('permissionsCampaign')
                .then((r) => {
                    this.campaign = r
                })
        }

    },

    computed: {
        me() {
            return this.$store.state.auth.me
        }
    },

}
</script>

<style scoped>

.menuContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 260px;
    min-height: 100vh;
    box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
    padding: 0 10px;
    overflow-x: auto;
}

.menuContainer .logoContainer {
    border-bottom: 1px inset #c3c3c3;
    padding: 10px 0;
}

.menuContainer .logoContainer img {
    max-width: 110px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.menuContainer .userContainer {
    border-bottom: 1px solid #c3c3c3;
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: 48px;
}

.menuContainer .userContainer:hover {
    cursor: pointer;
}

.menuContainer .userContainer .avatar img {
    max-width: 35px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
}

.menuContainer .userContainer p {
    color: #000;
    margin: 0;
    padding-top: 5px;
}

.menuContainer .submenu li {
    padding: 5px 0;
}

.menuContainer .submenu li a {
    font-size: 16px;
}

.menuContainer .links a {
    display: block;
    text-decoration: none;
    color: #000;
    line-height: 1.5em;
    height: 35px;
}

.menuContainer .links a i {
    color: #686666;
    font-size: 16px;
    margin-right: 2px;
}

.link-route {
    display: block;
    padding: 10px;
    height: 45px;
    background-color: #ebebeb;
    text-decoration: none;
    color: #000000;
}

.link-route:hover {
    background-color: #153d51;
    color: #ffffff;
    -webkit-transition: background-color 500ms linear;
    -ms-transition: background-color 500ms linear;
    transition: background-color 500ms linear;
}

.linkContainer ul {
    padding: 0;
    list-style: none;
    margin: 10px 0 0;
}

.linkContainer ul:hover {
    cursor: pointer;
}

.linkContainer ul li {
    height: 45px;
    background-color: #ebebeb;
}

.linkContainer ul li a {
    color: #000000;
    text-decoration: none;
    font-size: 16px;
}

.linkContainer ul li img {
    margin: 0 0.25rem 0.20rem 0;
    height: 1em;
    text-align: center;
}

button {
    outline: none;
    border: 0;
    width: 100%;
    text-align: left;
}

/*.activeBackground {
  background-color: #36c1bd;
  color: white !important;
}*/

@media (max-width: 991px) {
    .menuContainer {
        left: -260px;
        transition: all 0.6s ease-in-out;
    }

    .openMenuContainer {
        left: 0;
        transition: all 0.6s ease-in-out;
    }
}
</style>


