import AdminComponent from "@/components/dashboard/AdminComponent";
import UserUpdatePhoto from "@/views/update/UserUpdatePhoto";
import UserUpdatePassword from "@/views/update/UserUpdatePassword";

export default {
    path: '/user',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'user-photo/', name: 'user-update-photo', component: UserUpdatePhoto},
        {path: 'user-password/', name: 'user-update-password', component: UserUpdatePassword}
    ]
}