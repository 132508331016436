export default {
    links: [
        {
            name: 'Condomínio',
            icons: 'icon building outline',
            route: '/',
        },

        {
            name: 'Alterar Foto',
            icons: 'icon image',
            route: '/user/user-photo/',
        },

        {
            name: 'Alterar Senha',
            icons: 'icon unlock alternate',
            route: '/user/user-password',
        },
    ],
}