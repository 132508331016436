import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'notice';

export default {

    loadNotices(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => {
                    context.commit('LOAD_NOTICE_APP', response.data)
                    return resolve(response.data)
                }).catch(() => {
                return reject()
            }).finally(() => context.commit('PRELOADER', false))
        })
    },
    
    storeNoticeAppAddItems(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}notice-add-notice`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeNoticeAppSynchronize(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}notice-synchronize`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeNoticeAppDelete(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}notice-delete-itens`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadNoticeAddItems(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}notice-add-notice-show/${formData.id}/${formData.idapp}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateNoticeAddItems(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}notice-add-notice-update`, formData)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

}