<template>
    <div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="errors !== null">
        <p v-for="(error,index) in errors" :key="index" v-text="error[0]"></p>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
</template>

<script>
export default {
    name: "ErrorComponent",

    props: {
        errors: {
            "required": false,
            "type": Object,
            "default": []
        },
    }
}
</script>

<style scoped>
.alert p {
    margin: 0;
}
</style>