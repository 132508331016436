<template>
    <button :title="items.title" :type="items.type" :class="['btn btn-secondary buttonOne']" @click="submit">
        <i :class="'icon ' + items.icon"></i>
        {{ items.name }}
    </button>
</template>

<script>
export default {
    name: "ButtonDefault",

    props: {

        items: {
            "type": Object,
            "default": []
        },

    },

    data() {
        return {
            buttonLoader: false,
        }
    },

    methods: {
        submit() {
            if (this.loderButton) {
                this.buttonLoader = true;
            }
            this.$emit('submit')
        }
    }
}
</script>

<style scoped>
.buttonOne {
    line-height: 20px;
    min-width: 40px;
    height: 40px;
    background: #FF7700;
    border-radius: 5px;
    border: 0;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.5s ease-out;
}

i.icon {
    margin: 0;
}

.buttonOne:active,
.buttonOne:focus,
.buttonOne:hover,
.buttonOne:focus-visible {
    background-color: #d67c1c;
    color: white;
    border-color: #d67c1c;
    box-shadow: none !important;
}

.preloderSend {
    padding-right: 40px;
}

.preloderSend:after {
    content: "";
    position: absolute;
    animation: load 3s infinite;
    margin-left: 4px;
}

@keyframes load {
    0% {
        content: "";
    }

    33% {
        content: ".";
    }

    66% {
        content: "..";
    }

    99% {
        content: "...";
    }
}
</style>