export default {
    namePage: 'Home',

    noResultsText: 'Nenhum resultado encontrado',
    noOptionsText: 'A lista está vazia',

    selectActiveInative: [
        { value: 1, label: 'Ativo' },
        { value: 2, label: 'Inativo' }
    ]

}